import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';

import errorBoundary from '@ifeng-fe/errorBoundary';

import Slides from '@ifeng-fe/ui_pc_slides';

import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class Slider extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };
    state = {
        current: 0,
    };

    swtichPage = (cur, total) => {
        this.setState({
            current: cur,
            total,
        });
    };
    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        const { current } = this.state;

        const sliderTxtTmpl = data => {
            return (
                <div className={style.s_ban_txt}>
                    <div className={style.textBg}>&nbsp;</div>
                    <div className={style.text_swiper}>
                        <h2>
                            <a href={data.url} target="_blank">
                                {data.title}
                            </a>
                        </h2>
                        <p>{data.summary}</p>
                    </div>
                </div>
            );
        };

        const sliderTmpl = function sliderTmpl(item) {
            return (
                <div
                    className={style.li}
                    style={{
                        float: 'left',
                        width: '100%',
                        height: '600px',
                        // background: `url(${item.thumbnail}) no-repeat center top`,
                    }}>
                    <a className={style.blockA} href={item.url} target="_blank" rel={rel}>
                        <img src={item.thumbnail} style={{ width: '100%', height: 600 }} />
                        <span className={style.bg} />
                    </a>
                </div>
            );
        };

        const config = {
            autoplay: false,
            arrows: true,
            dots: true,
            axis: 'horizonta',
            beginChange: this.swtichPage,
            dotStyle: style.dot,
            dotCurrentStyle: style.current,
            sliderTmpl,
        };

        const dom = (
            <div className={style.s_ban}>
                <div className={style.s_ban_main}>
                    <Slides content={content} config={config} />
                    {content[current] ? sliderTxtTmpl(content[current]) : ''}
                </div>
            </div>
        );

        return content.length > 0 ? dom : '';
    }
}
export default errorBoundary(Slider);
