import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Chip from 'Chip';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class HotNewsInner extends React.PureComponent {
    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const cell = content.map((item, i) => (
            <li key={i}>
                <a href={item.url} target="_blank" rel={rel}>
                    <span>&nbsp;</span> <img src={item.src} />
                </a>
                <p>
                    <a href={item.url} rel={rel} title={item.title || ''} target="_blank">
                        {item.title.length > 13 ? item.title.substring(0, 13) : item.title}
                    </a>
                </p>
            </li>
        ));

        return (
            <div className={style.h_list}>
                <ul className="clearfix">{cell}</ul>
            </div>
        );
    }
}

HotNewsInner.propTypes = { content: PropTypes.array };

HotNewsInner.defaultProps = {};

class HotNews extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    getDay = () => {
        const __day = new Date().getDate();
        const mon = new Date().getMonth() + 1;
        const month = mon < 10 ? `0${mon}` : mon;

        const day = __day < 10 ? `0${__day}` : __day;

        return `${month}/${day}`;
    };

    render() {
        const { content } = this.props;

        return (
            <div className={style.hot}>
                <div className={style.h_date}>{this.getDay()}</div>
                <Chip
                    id="20043"
                    type="recommend"
                    title="热点新闻"
                    groupName="首页"
                    content={content}
                    translate="formDataHotNews">
                    <HotNewsInner />
                </Chip>
                <div className={style.h_btn}>
                    <a href="/shanklist/innovationlist/19-35063-" target="_blank" rel={rel}>
                        要闻回顾
                    </a>
                </div>
            </div>
        );
    }
}

export default errorBoundary(HotNews);
