import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

import Slider from './slider';
import HotNews from './hotNews';
import NewsList from './newsList';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        const { slider, hotNews, newsList } = content;

        return (
            <div className={styles.col_wbf}>
                <Slider content={slider} />

                <div className={styles.bg_poz}>
                    <div className={styles.col_w960}>
                        <HotNews content={hotNews} />
                        <NewsList content={content} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;
