import React from 'react';
import PropTypes from 'prop-types';
// import { LazyImg } from '@ifeng-fe/ui_pc_lazyLoad';
import styles from './index.css';
import { formatDateTime2 } from '../../../../utils/timeUnit.js';
import { formatUrl } from '@ifeng-fe/public_method';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
/**
 * 定义 Card 组件
 * 接受两个参数
 * type参数有五个可能的值
 * smallVideoCard，smallCard，middleCard，bigCard，largeCard
 * 分别代表小视频卡片，小图文卡片，中型图片图文卡片，大卡片以及巨卡片
 * content参数代表卡片内数据
 */
class Card extends React.PureComponent {
    static propTypes = {
        type: PropTypes.string,
        content: PropTypes.object,
    };
    /**
     * 渲染组件
     */
    render() {
        const { type = null, content } = this.props;

        const { link, title, summary, newsTime, source, coverPic } = content;

        let boxStyle = null;

        if (type === 'smallVideoCard' || type === 'smallCard') {
            boxStyle = `${styles.l_box} ${styles.l_box1}`;
        } else if (type === 'middleCard') {
            boxStyle = `${styles.l_box} ${styles.l_box2}`;
        } else if (type === 'bigCard') {
            boxStyle = styles.l_box3;
        } else if (type === 'largeCard') {
            boxStyle = styles.l_pt;
        }

        return (
            <div className={boxStyle}>
                <div className={styles.pic}>
                    <a href={formatUrl(link)} target="_blank" rel={rel}>
                        <img
                            src={formatUrl(coverPic || '//p3.ifengimg.com/a/2018/0807/cover.jpg')}
                            className={styles.trans}
                        />
                    </a>
                    {type === 'smallVideoCard' ? (
                        <div className={styles.v_btn}>
                            <a href={formatUrl(link)} target="_blank" rel={rel}>
                                &nbsp;
                            </a>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                {type === 'middleCard' ? <div className={styles.zz}>&nbsp;</div> : ''}
                {type === 'bigCard' || type === 'largeCard' ? <div className={styles.textBg} /> : ''}
                <div className={styles.txt}>
                    <h3>
                        <a href={formatUrl(link)} target="_blank" rel={rel}>
                            {title}
                        </a>
                    </h3>
                    <p>{summary}</p>
                    {type !== 'bigCard' && type !== 'largeCard' ? (
                        <div className={`${styles.l_xx} clearfix`}>
                            <span className={styles.l_time}>{formatDateTime2(newsTime)}</span>
                            <span className={styles.l_source}>
                                <a>{source}</a>
                            </span>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }
}

export default Card;
