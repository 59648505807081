import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import Logo2 from './logo2.jpg';
import Ewm from './ewm.jpg';

import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * 定义 Footer 组件
 */
class Footer extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const contactList = content.logoList.map((each, index) => {
            return <p key={index}>{each}</p>;
        });

        const coopList = [];

        for (let i = 0; i < 3; i++) {
            const liArr = content.cooperationList.slice(i * 4, i * 4 + 4).map((each, index) => {
                return (
                    <li key={index}>
                        <a href={each.link} target="_blank" rel={rel}>
                            {each.name}
                        </a>
                    </li>
                );
            });

            if (i !== 2) {
                coopList.push(<ul key={i}>{liArr}</ul>);
            } else {
                coopList.push(
                    <ul className={styles.u_bn} key={i}>
                        {liArr}
                    </ul>,
                );
            }
        }

        return (
            <div className={`${styles.col_w1000} ${styles.bot} ${styles.mt20} clearfix`}>
                <div className={styles.box_300}>
                    <div className={styles.b_logo}>
                        <a href="#" target="_blank" rel={rel}>
                            <img src={Logo2} />
                        </a>
                    </div>
                    <div className={`${styles.b_xx} ${styles.mt20} clearfix`}>
                        <img src={Ewm} />
                        {contactList}
                    </div>
                </div>
                <div className={styles.box_650}>
                    <h4>合作媒体</h4>
                    <div className="clearfix">{coopList}</div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Footer);
