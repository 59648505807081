import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Header from './header';
import transform from 'chipDataTransform';
import InnovationFooter from '../components/footer/';
import Footer from './footer';

import BottomAffix from './bottomAffix';

/**
 * for this page
 */
import InnovationHeader from '../components/innovationHeader/';
import Content from './content';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
    }

    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;

        console.log(content);

        const contentData = {
            slider: content.slider,
            hotNews: content.hotNews,
            newsList: content.newsList,
        };
        const innovationNav = content.innovationNav;

        console.log('content=', content);

        return (
            <div className={styles.ip_col}>
                <Header content={content.nav} />
                <Chip id="20014" type="struct" title="创新频道导航" groupName="凤凰网创新频道" content={innovationNav}>
                    <InnovationHeader />
                </Chip>
                <Content content={content} />
                <Chip id="20017" type="struct" title="创新频道页尾" groupName="凤凰网创新频道" content={content.footer}>
                    <InnovationFooter />
                </Chip>
                <Footer content={content.footerBottom} />
                <BottomAffix />
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default errorBoundary(Layout);
