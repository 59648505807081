import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatUrl } from '@ifeng-fe/public_method';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * 定义 Nav 组件
 */
class InnovationHeader extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const {
            logo: { logo_img, logo_link },
            ul_list,
        } = content;

        const navList = ul_list.map((eachBox, index) => {
            if (index !== 1) {
                eachBox.nav_title.tit_link = `//innovation.ifeng.com/shanklist/innovationcard/${
                    eachBox.nav_title.tit_link
                }`;
            }

            const { nav_list } = eachBox;

            nav_list.forEach(each => {
                if (index !== 1) {
                    each.li_link = `//innovation.ifeng.com/shanklist/innovationcard/${each.li_link}`;
                }
            });

            const li_list = (
                <ul className={styles.nav_list}>
                    <li>
                        <a href={formatUrl(nav_list[0].li_link)} target="_blank" rel={rel}>
                            {nav_list[0].li_name}
                        </a>
                        <a href={formatUrl(nav_list[1].li_link)} target="_blank" rel={rel}>
                            {nav_list[1].li_name}
                        </a>
                    </li>
                    <li>
                        <a href={formatUrl(nav_list[2].li_link)} target="_blank" rel={rel}>
                            {nav_list[2].li_name}
                        </a>
                        <a href={formatUrl(nav_list[3].li_link)} target="_blank" rel={rel}>
                            {nav_list[3].li_name}
                        </a>
                    </li>
                </ul>
            );

            const bg = `n${index + 1}_bg`;

            return (
                <div className={`${styles.nav_box} clearfix`} key={index}>
                    <div className={`${styles.nav_tit} ${styles[bg]}`}>
                        <a href={formatUrl(eachBox.nav_title.tit_link)} target="_blank" rel={rel}>
                            {eachBox.nav_title.tit_name}
                        </a>
                    </div>
                    {li_list}
                </div>
            );
        });

        return (
            <div className={`${styles.col_wbf} ${styles.bg_top}`}>
                <div className={`${styles.col_w1000} ${styles.top} ${styles.mt25} clearfix`}>
                    <div className={styles.logo}>
                        <a href={logo_link} target="_blank" rel={rel}>
                            <img src={logo_img} title="凤凰网创新" alt="凤凰网创新" />
                        </a>
                    </div>
                    <div className={`${styles.nav} clearfix`}>{navList}</div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(InnovationHeader);
