import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import { addEventListener } from '@ifeng-fe/ui_base';
import errorBoundary from '@ifeng-fe/errorBoundary';

import Chip from 'Chip';

import Card from '../../../components/card';
// import { MyVideo } from '@ifeng-fe/ui_pc_video';
import { getIndexList } from '../../../../../services/api';

import Loadable from 'react-loadable';

const LoadaMyVideo = Loadable({
    loader: () => import('@ifeng-fe/ui_pc_video').then(({ MyVideo }) => MyVideo),
    loading() {
        return <div>Loading...</div>;
    },
});

class NewsList extends React.PureComponent {
    state = {
        eachPageNum: 9,
        content: [],
        contentIds: [],
        hasQequestIndex: [],
        lastIndex: 0,
        isLoading: false,
    };
    static propTypes = {
        content: PropTypes.object,
    };
    componentDidMount() {
        this.unHandleScroll = addEventListener(window, 'scroll', this.handleScroll);
        this.getData(0, 21);
    }

    componentWillUnmount() {
        this.unHandleScroll();
    }
    joinData = data => {
        const { content } = this.state;

        return [...content, ...data];
    };

    getData = async (index, size) => {
        try {
            await this.setState({ isLoading: true, hasQequestIndex: [...this.state.hasQequestIndex, index] });

            const res = await getIndexList(index, size, this.props.content);
            let data = res || [];

            data = data.filter(item => !~this.state.contentIds.indexOf(item.id));

            const newArr = this.joinData(data);
            const __lastIndex = newArr.length - 1;
            const contentIds = newArr.map(item => item.id);

            if (newArr[__lastIndex]) {
                this.setState({ content: newArr, contentIds, lastIndex: newArr[__lastIndex].index, isLoading: false });
            }
        } catch (e) {
            console.error(e);
        }
    };

    // 滚动条在Y轴上的滚动距离
    getScrollTop = () => {
        let scrollTop = 0;
        let bodyScrollTop = 0;
        let documentScrollTop = 0;

        if (document.body) {
            bodyScrollTop = document.body.scrollTop;
        }
        if (document.documentElement) {
            documentScrollTop = document.documentElement.scrollTop;
        }
        scrollTop = bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;

        return scrollTop;
    };

    // 文档的总高度

    getScrollHeight = () => {
        let scrollHeight = 0;
        let bodyScrollHeight = 0;
        let documentScrollHeight = 0;

        if (document.body) {
            bodyScrollHeight = document.body.scrollHeight;
        }
        if (document.documentElement) {
            documentScrollHeight = document.documentElement.scrollHeight;
        }
        scrollHeight = bodyScrollHeight - documentScrollHeight > 0 ? bodyScrollHeight : documentScrollHeight;

        return scrollHeight;
    };

    // 浏览器视口的高度

    getWindowHeight = () => {
        let windowHeight = 0;

        if (document.compatMode === 'CSS1Compat') {
            windowHeight = document.documentElement.clientHeight;
        } else {
            windowHeight = document.body.clientHeight;
        }

        return windowHeight;
    };

    /**
     * 滚动条滚动
     */
    handleScroll = () => {
        const { isLoading, lastIndex, hasQequestIndex, eachPageNum } = this.state;
        // 兼容各主流浏览器
        // const currentTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        // const windowHeight = document.body.clientHeight || document.documentElement.clientHeight;
        const bottomTop = this.getScrollTop() + this.getWindowHeight() === this.getScrollHeight();

        const __begin = lastIndex === 0 ? 0 : lastIndex + 1;

        if (bottomTop && !isLoading && lastIndex + eachPageNum <= 200 && !hasQequestIndex.includes(__begin)) {
            this.getData(this.state.content.length - 2, 21);
        }
    };

    render() {
        const { content } = this.state;

        const map = {
            article: 'smallCard',
            video: 'smallVideoCard',
            slide: 'middleCard',
            card16: 'bigCard',
            card24: 'largeCard',
            card16Video: 'bigCard',
        };

        // 播放器信息
        const playermsg = item => {
            return {
                width: 630,
                height: 368,
                isAuth: 'auth',
                autoPlay: false,
                poster: item.thumbnail || false,
                name: item.title || false,
            };
        };

        const list = content.map((item, i) => {
            if (item.chipId) {
                return (
                    <Chip
                        key={i}
                        id={item.chipId}
                        type="recommend"
                        title={item.chipTitle || ''}
                        groupName="信息流中的推荐位"
                        translate={item.translateFunction}
                        content={item}>
                        {item.type === 'card16video' ? (
                            <div className={style.videoCard}>
                                <LoadaMyVideo vid={item.id} playermsg={playermsg(item)} />
                            </div>
                        ) : (
                            <Card type={map[item.type] || 'smallCard'} />
                        )}
                    </Chip>
                );
            } else {
                return <Card key={i} type={map[item.type] || 'smallCard'} content={item} />;
            }
        });

        return <div className={style.list}>{list}</div>;
    }
}

export default errorBoundary(NewsList);
