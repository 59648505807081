import { jsonp } from '@ifeng-fe/ui_base';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;

/* eslint-enable */

if (typeof window === 'object' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

// TYPE  delete  将该数据在数据源中删掉 【数据去重】

const newsListData = (recData, index, data, TYPE, translateFunction) => {
    const newDataFunc = () => {
        // 参数recData index TYPE  都是数组，支持批量去重后，批量插入

        const getItemData = (recDataItem, __type, __translateFunction) => {
            if (recDataItem.data.type === 'video') {
                __type += 'video';
            }
            const item = {
                ...recDataItem.data,
            };
            const res = {
                translateFunction: __translateFunction,
                ...item,
                type: __type,
                ...recDataItem.chipInfo,
                thumbnails: item.thumbnails
                    ? item.thumbnails
                    : {
                          image: [
                              {
                                  url: item.thumbnail,
                              },
                          ],
                      },
            };

            return res;
        };

        // 去重
        const deleteDupcateItemFromData = (recDataItem, type, translateFunction) => {
            // const item = getItemData(recDataItem, type, translateFunction);

            if (recDataItem.data.id) {
                data = data.filter(ITEM => ITEM.id !== recDataItem.data.id);
            }
        };

        // 插入
        const insertItemToData = (recDataItem, i, type, translateFunction) => {
            const item = getItemData(recDataItem, type, translateFunction);

            if (item) {
                data.splice(i, 0, item);
            }
        };

        // 遍历需要操作的数据
        recData.forEach((__eachData, __dataIndex) => {
            const type = TYPE[__dataIndex];
            const __translateFunction = translateFunction[__dataIndex];

            deleteDupcateItemFromData(__eachData, type, __translateFunction);
        });

        recData.forEach((__eachData, __dataIndex) => {
            const type = TYPE[__dataIndex];
            const __translateFunction = translateFunction[__dataIndex];

            insertItemToData(__eachData, index[__dataIndex], type, __translateFunction);
        });
    };

    switch (TYPE) {
        // 在data中 将recData数据去重
        case 'delete':
            recData.forEach(item => {
                if (item && item.id) {
                    data = data.filter(each => each.id !== item.id);
                }
            });

            break;
        default:
            // 在data中 将recData数据去重后 将recData 插入data中
            newDataFunc();
            break;
    }

    return data;
};

// 获取创新首页信息流
export const getIndexList = async (beginIndex, size, dealData) => {
    try {
        const defaultEachPageNum = 9;
        const begin = beginIndex || 0;

        const result = await jsonp(`${apiBaseUrl}/innovation/index/list/${begin}/${size}/getIndexListByPage`, {
            jsonpCallback: 'getIndexListByPage',
            cache: false,
        });

        let data = result.data;

        // 先去重
        const sliderList = dealData.slider;

        const sliderListData = sliderList && sliderList.slice(0, 3);

        data = newsListData(sliderListData, 12, data, 'delete');

        const hotNewsList = dealData.hotNews;
        // await getHotNewsList();

        const hotNewsListData = hotNewsList.slice(0, 3);

        data = newsListData(hotNewsListData, 12, data, 'delete');

        // 再插入信息流中的推荐位
        // 先将所有的去重，再做插入动作

        const recFirst = {
            data: dealData.recFirst,
            chipInfo: {
                chipId: '30002',
                chipTitle: '信息流通栏上推荐位',
            },
        };
        // await getIndexRecFirst();
        const recSec = {
            data: dealData.recSec,
            chipInfo: {
                chipId: '30003',
                chipTitle: '信息流通栏推荐位',
            },
        };
        // await getIndexRecSec();
        const recThr = {
            data: dealData.recThr,
            chipInfo: {
                chipId: '30004',
                chipTitle: '信息流通栏下推荐位',
            },
        };
        // await getIndexRecThr();

        const insertData = [recFirst, recSec, recThr];
        const insertPos = [4, 8, 12];
        const insertType = ['card16', 'card24', 'card16'];
        const translateFunction = ['formDataRecFirst', 'formDataRecSec', 'formDataRecThr'];

        data = newsListData(insertData, insertPos, data, insertType, translateFunction);

        data = data.map((item, i) => {
            return {
                ...item,
                index: i,
            };
        });

        return data.map(item => {
            const getCoverPic = () => {
                let res = '';

                if (item.thumbnails) {
                    if (typeof item.thumbnails === 'string') {
                        res =
                            JSON.parse(item.thumbnails).image &&
                            JSON.parse(item.thumbnails).image[0] &&
                            JSON.parse(item.thumbnails).image[0].url;
                    } else {
                        res = item.thumbnails.image && item.thumbnails.image[0] && item.thumbnails.image[0].url;
                    }
                } else if (item.thumbnail && item.thumbnail !== '') {
                    res = item.thumbnail;
                }

                return res;
            };

            return {
                ...item,
                index: item.index,
                type: item.type,
                coverPic: getCoverPic(),
                link: item.url.indexOf('://') > -1 ? item.url : `//${item.url}`,
                title: item.title,
                card_content: item.summay || '',
                time: item.newsTime,
                source: item.source,
            };
        });
    } catch (e) {
        console.error(e);
    }
};
