import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Footer from '@ifeng-fe/container_channel/common/client/components/footer';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * 定义 Footer 组件
 */
class BottomFooter extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const footer = (
            <div key="footer" className={styles.footer}>
                <Footer content={content} />
            </div>
        );

        return footer;
    }
}

export default errorBoundary(BottomFooter);
